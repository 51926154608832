<template>
  <div class="personal-settings-panel">
    <v-tabs dark color="#292932" v-model="personalSettingsTab">
      <v-tab class="tab" key="tips">Tips & Referral</v-tab>
      <v-tab class="tab" key="twitch">Twitch</v-tab>
      <v-tab class="tab" key="audio">Audio</v-tab>
      <v-tab-item key="tips">
        <div class="tab-item-container px-3">
          <v-layout d-flex column class="mb-2 mt-1">
            <v-flex class="tab-item-container__info-text text-center mt-3 mb-3">
              The Tip Jar and Referral Link can be toggled on in the ‘Show’ menu
              of the host toolbar in meeting mode
            </v-flex>

            <v-layout d-flex column class="tab-item-container__list">
              <h3 class="mb-1">Tip Jar</h3>
              <WeveTextField
                class="mt-2"
                label="Venmo QR code URL"
                v-model="venmo"
                placeholder="www.venmo.com/johndoe"
              />
              <WeveTextField
                class="mt-4"
                v-model="paypal"
                label="Paypal username"
                placeholder="johndoe"
              />
            </v-layout>

            <hr class="hr-divider mt-4" />

            <v-layout d-flex column class="tab-item-container__list mt-3">
              <h3>Referral Link</h3>
              <WeveTextField
                class="mt-3"
                label="Referral link"
                placeholder="e.g. http://landingpage.com/johndoe"
                v-model="referralLink"
              />
              <v-layout d-flex align-center justify-space-between>
                <span> Show end game referral link iframe </span>
                <v-switch
                  style="flex: none !important"
                  color="#8680FF"
                  v-model="showReferralLinkIframe"
                />
              </v-layout>
            </v-layout>

            <v-btn
              class="text-xs-center mt-5 tab-item-container__action-btn"
              round
              block
              :loading="processingSaveReferral"
              @click="saveTipsAndReferral"
            >
              SAVE
            </v-btn>
          </v-layout>
        </div>
      </v-tab-item>
      <v-tab-item key="twitch">
        <div style="padding: 0 13px">
          <WeveTextField
            class="mt-3"
            label="Twitch Name"
            v-model="twitchAccountName"
            placeholder="Weve2"
          />
          <WeveTextField
            class="mt-3"
            v-model="twitchAccountUrl"
            label="Twitch URL"
            placeholder="johndoe"
          />
          <WeveTextField
            class="mt-3"
            v-model="twitchAccountApiKey"
            label="Twitch API Key"
            placeholder="live_78493h48h8wyerr"
          />
          <v-btn
            class="text-xs-center mt-5"
            round
            block
            color="rgb(255, 212, 76)"
            style="
              font-weight: bold;
              text-align: center;
              color: black !important;
            "
            :loading="processingTwitchSetting"
            @click="updateTwitchAccount"
            >SAVE</v-btn
          >
        </div>
      </v-tab-item>
      <v-tab-item key="audio">
        <div class="tab-item-container">
          <v-layout d-flex column class="mb-2 mt-1">
            <v-layout
              d-flex
              align-center
              justify-space-between
              class="tab-item-container__list"
            >
              <h3 class="tab-item-container__list-title">
                Turn off auto music triggers
              </h3>
              <v-switch
                style="flex: none !important"
                color="#8680FF"
                v-model="disableAutoMusicModel"
              />
            </v-layout>
            <div class="tab-item-container__info-text">
              When toggled on, music attached to activities will not
              automatiaclly play
            </div>
          </v-layout>
          <v-btn
            class="text-xs-center mt-5 tab-item-container__action-btn"
            round
            block
            @click="updateAutoMusicSetting"
            :loading="processingAudioSetting"
            >SAVE</v-btn
          >
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { WeveTextField } from "@weve/ui"
import { mapGetters, mapActions } from "vuex"
import { db } from "@/firebase"

export default {
  name: "PersonalSettingsPanel",
  components: {
    WeveTextField
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["game", "gameID", "orgID"]),
    showTipJar: {
      get() {
        return !!this.game?.showTipJar
      },
      set(value) {
        db.auxiliary()
          .ref(`org/${this.orgID}/games/${this.gameID}/showTipJar`)
          .set(!!value)
      }
    },
    disableAutoMusic: {
      get() {
        return this.game?.disableAutoMusic ?? false
      },
      async set(value) {
        this.disableAutoMusicModel = value
      }
    }
  },
  data() {
    return {
      personalSettingsTab: "",
      twitchAccountName: "",
      twitchAccountUrl: "",
      twitchAccountApiKey: "",
      venmo: "",
      paypal: "",
      referralLink: "",
      showReferralLinkIframe: false,
      disableAutoMusicModel: null,
      processingSaveReferral: false,
      processingAudioSetting: false,
      processingTwitchSetting: false
    }
  },
  watch: {
    disableAutoMusic: {
      handler(val) {
        this.disableAutoMusicModel = val ?? false
      },
      immediate: true
    }
  },
  mounted() {
    this.venmo = this.user.venmo
    this.paypal = this.user.paypal
    this.referralLink = this.user.referralLink
    this.showReferralLinkIframe = Boolean(this.user.showReferralLinkIframe)

    const { twitchAccount = {} } = this.user

    this.twitchAccountName = twitchAccount.name || ""
    this.twitchAccountUrl = twitchAccount.url || ""
    this.twitchAccountApiKey = twitchAccount.apiKey || ""
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async saveTipsAndReferral() {
      this.processingSaveReferral = true
      await this.$store.dispatch("updateUser", {
        userID: this.user.id,
        obj: {
          venmo: this.venmo || null,
          paypal: this.paypal || null,
          referralLink: this.referralLink || null,
          showReferralLinkIframe: Boolean(this.showReferralLinkIframe)
        }
      })
      this.processingSaveReferral = false
    },
    async updateTwitchAccount() {
      this.processingTwitchSetting = true
      await this.$store.dispatch("updateUser", {
        userID: this.user.id,
        obj: {
          twitchAccount: {
            name: this.twitchAccountName,
            url: this.twitchAccountUrl,
            apiKey: this.twitchAccountApiKey
          }
        }
      })
      this.processingTwitchSetting = false
    },
    async updateAutoMusicSetting() {
      this.processingAudioSetting = true
      console.log(this.disableAutoMusicModel, "this.disableAutoMusicModel")
      await db
        .auxiliary()
        .ref(`org/${this.orgID}/games/${this.gameID}/disableAutoMusic`)
        .set(this.disableAutoMusicModel)
      this.processingAudioSetting = false
    }
  }
}
</script>
<style lang="scss">
.personal-settings-panel {
  .v-tabs__bar {
    background-color: #1e2025;
  }

  .accent {
    background-color: #8680ff !important;
  }

  .hr-divider {
    margin: unset;
    height: 1px;
    background: #3e4145;
    border: none;
  }

  .tab-item-container {
    padding: 0 13px;

    &__action-btn {
      background: rgb(255, 212, 76) !important;
      font-weight: bold;
      text-align: center;
      color: black !important;
    }

    &__info-text {
      color: #798294;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      // text-align: left;
    }

    &__list {
      &-title {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
      }
    }
  }
}
</style>
