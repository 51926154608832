import { computed, ref, watch, onBeforeUnmount } from "@vue/composition-api"
import useChime from "@/Chime/use/useChime"
import useChimeRoom from "@/Chime/use/useChimeRoom"
import { StreamType } from "@/Chime/use/enums"

export default function useChimeLobbyStream(roomId: string) {
  const {
    joinWithScreen,
    sessions,
    abandon,
    startStreaming,
    stopStreaming,
    composer
  } = useChime()
  const { meeting } = useChimeRoom(roomId, StreamType.LOBBY)
  const playbackUrl = computed(() => meeting.value?.channelPlaybackUrl)

  const working = ref(false)

  const streaming = computed(() => {
    if (playbackUrl.value) return true
    if (meeting.value?.channelArn) return true
    if (meeting.value?.mediaPipelineId) return true
    if (sessions.value[roomId] != null) return true

    return false
  })

  async function stopStreamingAndAbandon() {
    composer.value?.off("ended", stopStreamingAndAbandon)
    composer.value?.stop()

    const errors = []

    try {
      await stopStreaming(roomId)
    } catch (e) {
      errors.push(e)
    }
    try {
      await abandon(roomId)
    } catch (e) {
      errors.push(e)
    }
    if (errors.length > 0) {
      throw new Error(errors.map(e => e.message).join("\n"))
    }
  }

  onBeforeUnmount(() => {
    composer.value?.off("ended", stopStreamingAndAbandon)
    composer.value?.stop()
    stopStreamingAndAbandon()
  })

  watch(composer, (newValue, oldValue) => {
    oldValue?.off("ended", stopStreamingAndAbandon)
    oldValue?.stop()
    newValue?.once("ended", stopStreamingAndAbandon)
  })

  async function toggle() {
    working.value = true

    try {
      if (streaming.value) {
        await stopStreamingAndAbandon()
      } else {
        await joinWithScreen(roomId)
        await startStreaming(roomId, StreamType.LOBBY)
      }
    } catch (e) {
      console.error(e)
    }

    working.value = false
  }

  return {
    working,
    toggle,
    streaming
  }
}
