var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-drawer",
    _vm._b(
      {
        key: _vm.refreshKey,
        attrs: {
          right: "",
          width: 240,
          height: _vm.height,
          "submenu-width": _vm.subMenuWidth,
          "show-sub-menu": _vm.showSubMenu,
          "sub-menu-title": _vm.subSettings,
          "menu-background": _vm.menuBackground,
          "sub-menu-background": _vm.subMenuBackground,
          offsetY: _vm.offsetY,
        },
        on: { updateShowSubMenu: _vm.updateShowSubMenu, refresh: _vm.refresh },
        scopedSlots: _vm._u([
          {
            key: "menu-content",
            fn: function () {
              return [
                _c("div", { staticClass: "host-sidebar" }, [
                  _c(
                    "div",
                    { staticClass: "host-sidebar__content" },
                    [
                      _c(
                        "h1",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            "padding-top": "10px",
                            "padding-bottom": "15px",
                          },
                        },
                        [_vm._v(" Menu ")]
                      ),
                      _vm.isModerator
                        ? [
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Select Moderator",
                                svgPrepend: "user",
                                svgPrependSize: 12,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.SelectModerator
                                  )
                                },
                              },
                            }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Reset Game",
                                svgPrepend: "loop",
                                svgPrependSize: 14,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetGame()
                                },
                              },
                            }),
                            _c(
                              "MainDrawerBtn",
                              {
                                attrs: {
                                  title: "Raffle in Game",
                                  iconPrepend: "track_changes",
                                },
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-auto shrink",
                                  attrs: { color: "#8680FF" },
                                  model: {
                                    value: _vm.hasRaffleInGame,
                                    callback: function ($$v) {
                                      _vm.hasRaffleInGame = $$v
                                    },
                                    expression: "hasRaffleInGame",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("hr", { staticClass: "mb-1 mt-1" }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Test Attendees",
                                svgPrepend: "attendee-avatar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.TestAttendees
                                  )
                                },
                              },
                            }),
                            _c(
                              "MainDrawerBtn",
                              {
                                attrs: {
                                  title: "Spacebar Hints",
                                  svgPrepend: "question-circle-light",
                                },
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-auto shrink",
                                  attrs: { color: "#8680FF" },
                                  model: {
                                    value: _vm.spacebarHints,
                                    callback: function ($$v) {
                                      _vm.spacebarHints = $$v
                                    },
                                    expression: "spacebarHints",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "View hotkeys",
                                iconPrepend: "keyboard",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.Hotkeys
                                  )
                                },
                              },
                            }),
                          ]
                        : [
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Text Settings",
                                svgPrepend: "chat-outlined",
                                chevron: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.TextSettings
                                  )
                                },
                              },
                            }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Personal Settings",
                                svgPrepend: "personal-settings",
                                chevron: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.PersonalSettings
                                  )
                                },
                              },
                            }),
                            _c("hr", { staticClass: "mb-1 mt-1" }),
                            _c(
                              "MainDrawerBtn",
                              {
                                attrs: {
                                  title: "Auditors",
                                  svgPrepend: "auditors",
                                  svgPrependSize: 15,
                                },
                              },
                              [
                                _c("div", { staticClass: "auditors__count" }, [
                                  _vm._v(" " + _vm._s(_vm.auditorsQty) + " "),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  left: "",
                                  "open-delay": 450,
                                  disabled: !_vm.isCopyLinkTTVisible,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "MainDrawerBtn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                title: "Copy invite link",
                                                svgPrepend: "copy",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyLink()
                                                },
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.isCopyLinkTTVisible,
                                  callback: function ($$v) {
                                    _vm.isCopyLinkTTVisible = $$v
                                  },
                                  expression: "isCopyLinkTTVisible",
                                },
                              },
                              [
                                _c("span", { staticClass: "left" }, [
                                  _vm._v(" Link copied "),
                                ]),
                              ]
                            ),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Select Moderator",
                                svgPrepend: "user",
                                svgPrependSize: 12,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.SelectModerator
                                  )
                                },
                              },
                            }),
                            _c("hr", { staticClass: "mb-1 mt-1" }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Reset Game",
                                svgPrepend: "loop",
                                svgPrependSize: 14,
                              },
                              on: {
                                click: [
                                  function ($event) {
                                    if (
                                      $event.ctrlKey ||
                                      $event.shiftKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    ) {
                                      return null
                                    }
                                    return _vm.resetGame()
                                  },
                                  function ($event) {
                                    if (!$event.shiftKey) {
                                      return null
                                    }
                                    return _vm.deletePlayData()
                                  },
                                ],
                              },
                            }),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "nudge-width": 100,
                                  "offset-y": "",
                                  "z-index": "1111",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var onMenu = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "host-sidebar__btn-item",
                                              attrs: { flat: "" },
                                            },
                                            Object.assign({}, onMenu)
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: { color: "red" },
                                                attrs: { small: "" },
                                              },
                                              [_vm._v(" outlined_flag ")]
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("Flag content")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "v-list",
                                  { attrs: { dark: "" } },
                                  [
                                    _vm._l(
                                      _vm.flagOptions,
                                      function (item, index) {
                                        return [
                                          item.type
                                            ? _c("v-divider", {
                                                key: item + "-" + index,
                                              })
                                            : _c(
                                                "v-list-tile",
                                                {
                                                  key: item + "-" + index,
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.callAction(
                                                        item.action,
                                                        item.title
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("hr", { staticClass: "mb-1 mt-1" }),
                            _c("MainDrawerBtn", {
                              class: {
                                active: _vm.isStreamingWithChime,
                                disabled:
                                  _vm.isTryingToStream || _vm.game.stream,
                              },
                              attrs: {
                                title: "Stream with Chime",
                                svgPrepend: "rss-feed",
                                svgPrependSize: 12,
                              },
                              on: { click: _vm.toggleChimeStream },
                            }),
                            _c("MainDrawerBtn", {
                              class:
                                _vm.game.stream ||
                                _vm.isTryingToStream ||
                                _vm.isStreamingWithChime
                                  ? "disabled"
                                  : null,
                              attrs: {
                                title: "Stream with Twitch",
                                svgPrepend: "twitch-logo",
                                svgPrependSize: 12,
                              },
                              on: { click: _vm.onLiveStream },
                            }),
                            _c("hr", { staticClass: "mb-1 mt-1" }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "Test Attendees",
                                svgPrepend: "attendee-avatar",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.TestAttendees
                                  )
                                },
                              },
                            }),
                            _c("MainDrawerBtn", {
                              attrs: {
                                title: "View hotkeys",
                                iconPrepend: "keyboard",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleSubSettingsBar(
                                    _vm.SUB_SETTINGS_BARS.Hotkeys
                                  )
                                },
                              },
                            }),
                            _c(
                              "MainDrawerBtn",
                              {
                                attrs: {
                                  title: "Raffle in Game",
                                  iconPrepend: "track_changes",
                                },
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-auto shrink",
                                  attrs: { color: "#8680FF" },
                                  model: {
                                    value: _vm.hasRaffleInGame,
                                    callback: function ($$v) {
                                      _vm.hasRaffleInGame = $$v
                                    },
                                    expression: "hasRaffleInGame",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "MainDrawerBtn",
                              {
                                attrs: {
                                  title: "Spacebar Hints",
                                  svgPrepend: "question-circle-light",
                                },
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-auto shrink",
                                  attrs: { color: "#8680FF" },
                                  model: {
                                    value: _vm.spacebarHints,
                                    callback: function ($$v) {
                                      _vm.spacebarHints = $$v
                                    },
                                    expression: "spacebarHints",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.showHybridToggles
                              ? _c(
                                  "MainDrawerBtn",
                                  {
                                    attrs: {
                                      title: "Presenter's sidebar",
                                      svgPrepend: "sidebar-right",
                                    },
                                  },
                                  [
                                    _c("v-switch", {
                                      staticClass: "ml-auto shrink",
                                      attrs: { color: "#8680FF" },
                                      model: {
                                        value: _vm.presentersSidebar,
                                        callback: function ($$v) {
                                          _vm.presentersSidebar = $$v
                                        },
                                        expression: "presentersSidebar",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showHybridToggles
                              ? _c("QrCodeActivitySwitch")
                              : _vm._e(),
                            _vm.showHybridToggles
                              ? [
                                  _c("hr", { staticClass: "mb-1 mt-1" }),
                                  _c("PushToTalkSwitch"),
                                  _c("HybridRoomAudioSettings", {
                                    staticClass: "mt-3",
                                    staticStyle: { padding: "0 15px" },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "submenu-content",
            fn: function () {
              return [
                _c("div", { staticClass: "host-sidebar" }, [
                  _c(
                    "div",
                    { staticClass: "host-sidebar__content" },
                    [
                      _vm.subSettings === _vm.SUB_SETTINGS_BARS.SelectModerator
                        ? _c("select-moderator-panel")
                        : _vm._e(),
                      _vm.subSettings === _vm.SUB_SETTINGS_BARS.PersonalSettings
                        ? _c("personal-settings-panel")
                        : _vm._e(),
                      _vm.subSettings === _vm.SUB_SETTINGS_BARS.TextSettings
                        ? _c("text-settings-panel")
                        : _vm._e(),
                      _vm.subSettings === _vm.SUB_SETTINGS_BARS.Hotkeys
                        ? _c("hotkeys-sidebar")
                        : _vm._e(),
                      _vm.subSettings === _vm.SUB_SETTINGS_BARS.TestAttendees
                        ? _c("TestAttendees")
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.state.value,
          callback: function ($$v) {
            _vm.$set(_vm.state, "value", $$v)
          },
          expression: "state.value",
        },
      },
      "custom-drawer",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "Popup",
        {
          attrs: {
            header: "What's the issue with this mission?",
            isOpen: _vm.flagDialogVisibility,
          },
          on: { onClose: _vm.closeFlagDialog },
        },
        [
          _c("rtb-textarea", {
            ref: "flagArea",
            staticClass: "black--text",
            model: {
              value: _vm.flagMessage,
              callback: function ($$v) {
                _vm.flagMessage = $$v
              },
              expression: "flagMessage",
            },
          }),
          _c(
            "rtb-button",
            {
              attrs: { color: "danger" },
              on: {
                click: function ($event) {
                  return _vm.submitFlag()
                },
              },
            },
            [_vm._v(" SUBMIT ")]
          ),
        ],
        1
      ),
      _c(
        "Popup",
        {
          attrs: {
            header: "What is your suggestion?",
            isOpen: _vm.suggestDialogVisibility,
          },
          on: { onClose: _vm.closeFlagDialog },
        },
        [
          _c("rtb-textarea", {
            ref: "suggestionArea",
            staticClass: "black--text",
            model: {
              value: _vm.flagMessage,
              callback: function ($$v) {
                _vm.flagMessage = $$v
              },
              expression: "flagMessage",
            },
          }),
          _c(
            "rtb-button",
            {
              attrs: { color: "danger" },
              on: {
                click: function ($event) {
                  return _vm.submitFlag()
                },
              },
            },
            [_vm._v(" SUBMIT ")]
          ),
        ],
        1
      ),
      _vm.editGameSettings
        ? _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.editGameSettings,
                persistent: "",
                "no-click-animation": "",
                width: "1024px",
              },
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.passedGame },
                on: {
                  close: function ($event) {
                    _vm.editGameSettings = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }