<template>
  <div class="text-settings-panel px-2">
    <div class="mb-3">
      <v-layout column>
        <v-flex
          class="mr-1"
          d-flex
          justify-space-between
          align-center
          v-for="(chatOption, index) in textChatOptionsComputed"
          :key="index"
        >
          <div>
            <v-icon small class="mr-1"> chat </v-icon>
            <span>{{ chatOption.label }}</span>
          </div>
          <v-switch
            style="flex: none !important"
            color="#8680FF"
            v-model="textChatComputed[chatOption.value]"
          />
        </v-flex>
      </v-layout>
      <v-btn
        @click="clearTeamChat"
        style="font-weight: bold"
        color="#FF4060"
        round
        >CLEAR CHAT</v-btn
      >
    </div>
    <hr />
    <v-layout d-flex align-center justify-space-between>
      <div>
        <v-icon small class="mr-1"> closed_caption </v-icon>
        <span>Closed captions</span>
      </div>
      <v-switch
        style="flex: none !important"
        color="#8680FF"
        v-model="speechToText"
      />
    </v-layout>
    <weve-select-field
      class="text-settings-panel__languages_select"
      v-if="speechToText"
      :disabled="!speechToText"
      v-model="game.speechLanguage"
      :options="speechLanguages"
      identity="languageCode"
      option-label="language"
      option-value="languageCode"
      label="Language"
      @input="onLanguageChange"
    >
    </weve-select-field>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import speechLanguages from "./speechLanguages.json"
import ChatService from "@/components/Chat/index.js"

const AVAILABLE_CHAT_CONTEXT = ChatService.allChatContext()

import { WeveSelectField } from "@weve/ui"

export default {
  name: "TextSettingsPanel",
  components: {
    WeveSelectField
  },
  data() {
    return {
      speechLanguages,
      clearingChat: false,
      localGame: {},
      textChatOptions: [
        {
          value: AVAILABLE_CHAT_CONTEXT.TEAM,
          label: "Team Chat"
        },
        {
          value: AVAILABLE_CHAT_CONTEXT.GAME,
          label: "Room Chat"
        },
        {
          value: AVAILABLE_CHAT_CONTEXT.LOBBY,
          label: "Lobby Chat"
        }
      ]
    }
  },
  computed: {
    ...mapGetters(["game", "gameID", "orgID"]),
    ...mapGetters("auth", ["user", "client", "hasPreGame", "clientID"]),
    speechToText: {
      get() {
        return !!this.game.speechToText
      },
      async set(value) {
        await this.updateGameAny({
          theKey: this.gameID,
          speechToText: value
        })
      }
    },
    textChatOptionsComputed() {
      return !this.hasPreGame
        ? this.textChatOptions.filter(
            ctx => ctx.value !== AVAILABLE_CHAT_CONTEXT.LOBBY
          )
        : this.textChatOptions
    },
    textChatComputed() {
      const _this = this

      const obj = {}

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.TEAM, {
        get() {
          return _this.$store.getters.isTeamChatOn()
        },
        async set(val) {
          await _this.updateGame(AVAILABLE_CHAT_CONTEXT.TEAM, val)
        }
      })

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.GAME, {
        get() {
          return _this.$store.getters.isRoomChatOn()
        },
        async set(val) {
          await _this.updateGame(AVAILABLE_CHAT_CONTEXT.GAME, val)
        }
      })

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.LOBBY, {
        get() {
          return _this.$store.getters.isLobbyChatOn()
        },
        async set(val) {
          await _this.updateGame(AVAILABLE_CHAT_CONTEXT.LOBBY, val)
        }
      })

      return obj
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async onLanguageChange(value) {
      await this.updateGameAny({
        theKey: this.gameID,
        speechLanguage: value
      })
    },
    async clearTeamChat() {
      this.clearingChat = true
      await ChatService.clearChat(
        [AVAILABLE_CHAT_CONTEXT.TEAM, AVAILABLE_CHAT_CONTEXT.GAME],
        { gameID: this.gameID, orgID: this.orgID, clientID: this.clientID }
      )

      // let's reload the chat for everyone
      await this.updateGameAny({ theKey: this.gameID, textChat: false })

      setTimeout(async () => {
        await this.updateGameAny({ theKey: this.gameID, textChat: true })
      }, 600)

      this.clearingChat = false
    },
    async updateGame(key, value) {
      await this.updateGameAny({
        theKey: this.gameID,
        [key]: value
      })
    }
  }
}
</script>
<style lang="scss">
.text-settings-panel {
  color: #798294;
  hr {
    margin: 0 1px;
    height: 1px;
    background: #3e4145;
    border: none;
  }

  &__languages_select {
    .weve-select__option {
      padding: 2px 20px 8px 0;
    }
  }
}
</style>
