var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hot-key-icon",
      class: { "hot-key-icon--sm": _vm.hotkey.sm || _vm.sm },
    },
    [
      _vm.hotkey.iconName || _vm.iconName
        ? _c("SvgIcon", {
            attrs: {
              folder: "hotkeys",
              name: _vm.hotkey.iconName || _vm.iconName,
              width: _vm.hotkey.iconWidth || _vm.iconWidth,
              height: _vm.hotkey.iconHeight || _vm.iconHeight,
            },
          })
        : _vm._e(),
      _vm.hotkey.text || _vm.text
        ? _c("span", {
            staticClass: "hot-key-icon__text",
            domProps: { innerHTML: _vm._s(_vm.hotkey.text || _vm.text) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }