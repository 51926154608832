var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "sub-settings-bar__teams", attrs: { column: "" } },
    [
      _c(
        "div",
        { staticClass: "sub-settings-bar__teams--container" },
        _vm._l(_vm.teams, function (team) {
          return _c(
            "v-layout",
            {
              key: team.id,
              staticClass: "sub-settings-bar__teams--team",
              attrs: { column: "", "no-wrap": "" },
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "sub-settings-bar__teams--team__name_delete",
                  attrs: { row: "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "d-flex sub-settings-bar__teams--team__name align-self-start",
                    },
                    [_vm._v(" " + _vm._s(team.name) + " ")]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "d-flex sub-settings-bar__teams--team__delete align-self-end shrink",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "" },
                          on: {
                            click: function ($event) {
                              return _vm.dismissTeam(team)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "sub-settings-bar__teams--team__controls",
                  attrs: { row: "" },
                },
                [
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c("button", {
                      staticClass: "circle minus",
                      on: {
                        click: function ($event) {
                          return _vm.removeFirstTestUserFromTeam(team)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "v-flex",
                    { staticClass: "team-value", attrs: { grow: "" } },
                    [_c("span", [_vm._v(_vm._s(team.count))])]
                  ),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c("button", {
                      staticClass: "circle plus",
                      on: {
                        click: function ($event) {
                          return _vm.addTestUser(team)
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-flex",
        { staticClass: "sub-settings-bar__teams--team--add-team mt-3" },
        [
          _c(
            "button",
            { attrs: { "d-flex": "" }, on: { click: _vm.addTeam } },
            [
              _c("div", { staticClass: "circle plus" }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    flex: "1 1 auto",
                    "justify-content": "center",
                    "margin-left": "-22px",
                  },
                },
                [_vm._v(" Add Team ")]
              ),
            ]
          ),
        ]
      ),
      _vm.hasHeadlessUsers
        ? _c(
            "v-btn",
            {
              staticClass: "mt-4 danger",
              attrs: { flat: "", small: "" },
              on: {
                click: function ($event) {
                  return _vm.deleteAll()
                },
              },
            },
            [_vm._v(" DELETE TEST PLAYERS ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }