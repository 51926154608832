


























































































































































































































































































































import Vue from "vue"
import { mapState, mapActions, mapGetters } from "vuex"
import { computed, watch } from "@vue/composition-api"

import useStore from "@/use/useStore"
import useChimeLobbyStream from "@/Chime/use/useChimeLobbyStream"

import { db } from "@/firebase"

import { RtbControlButton, RtbTextarea, RtbButton } from "@/components/ui"
import Popup from "@/components/Popups/Popup.vue"
import TestAttendees from "@/components/GroupTeams/Common/HostToolBar/SubPages/TestAttendees.vue"

import { Role } from "@/helpers"
import Mode from "@shared/enums/Mode"

import CustomDrawer from "@/components/CustomDrawer.vue"
import NavigationService from "@/services/navigation.service"
import GameSettings from "@/components/Game/GameSettings.vue"

import RaffleService from "@/modules/raffle/services"

import {
  flagOptions,
  SUB_SETTINGS_BARS
} from "@/components/drawers/main/MainDrawer.consts"

import { sendEmergencyTicket } from "@/services/api.service"
import PersonalSettingsPanel from "@/components/GroupTeams/Common/HostSideBarPanels/PersonalSettingsPanel.vue"
import TextSettingsPanel from "@/components/GroupTeams/Common/HostSideBarPanels/TextSettingsPanel.vue"
import SelectModeratorPanel from "@/components/GroupTeams/Common/HostSideBarPanels/SelectModeratorPanel.vue"
import HotkeysSidebar from "@/components/drawers/hot-keys/HotkeysSidebar.vue"
import TwilioStreamActionTypes from "@/store/modules/TwilioStream/action-types"

import state from "./index"
import MainDrawerBtn from "./MainDrawerBtn.vue"
export default Vue.extend({
  name: "MainDrawer",
  components: {
    HybridRoomAudioSettings: () => import("./HybridRoomAudioSettings.vue"),
    PushToTalkSwitch: () => import("./PushToTalkSwitch.vue"),
    QrCodeActivitySwitch: () => import("./QrCodeActivitySwitch.vue"),
    MainDrawerBtn,
    SelectModeratorPanel,
    TextSettingsPanel,
    PersonalSettingsPanel,
    TestAttendees,
    GameSettings,
    Popup,
    RtbControlButton,
    CustomDrawer,
    HotkeysSidebar,
    RtbTextarea,
    RtbButton
  },
  props: {
    height: {
      type: String
    }
  },
  setup() {
    const { store } = useStore()

    const {
      toggle: toggleChimeStream,
      working,
      streaming: isStreamingWithChime
    } = useChimeLobbyStream(store.getters.game?.id)

    const isTwilioStreamWorking = computed(
      () => store.getters["TwilioStream/working"]
    )

    const isTryingToStream = computed(
      () => isTwilioStreamWorking.value || working.value
    )

    return {
      isTryingToStream,
      toggleChimeStream,
      isStreamingWithChime
    }
  },
  data() {
    return {
      subSettings: false,
      Role,
      editGameSettings: false,
      passedGame: null,
      SUB_SETTINGS_BARS,
      working: false,
      flagDialogVisibility: false,
      suggestDialogVisibility: false,
      flagMessage: "",
      flagOptions,
      state,
      showSubMenu: false,
      subMenuWidth: 270,
      personalSettingsTab: "",
      menuBackground: "#292932",
      subMenuBackground: null,
      isCopyLinkTTVisible: false,
      refreshKey: 0
    }
  },
  computed: {
    ...mapGetters("TwilioStream", { twilioStreamWorking: "working" }),
    ...mapState(["gameID", "orgID"]),
    ...mapGetters("auth", [
      "clientID",
      "hasPreGame",
      "isModerator",
      "client",
      "isHost"
    ]),
    ...mapGetters({
      onlineUsers: "onlineUsersArray",
      missionID: "currentMission"
    }),
    ...mapGetters([
      "moderatorID",
      "user",
      "settingsBar",
      "game",
      "gameStatus",
      "urlID"
    ]),
    showHybridToggles() {
      return this.isHost && this.isHybridRoom
    },
    isHybridRoom() {
      return this.$store.getters["auth/isHybridRoom"]
    },
    offsetY() {
      return this.gameStatus.currentMode === Mode.Meeting ? 0 : 40
    },
    hasRaffleInGame: {
      get() {
        return this.gameStatus?.raffle?.raffleInGame ?? false
      },
      set(value) {
        this.raffleService.setRaffleInGame(this.orgID, this.gameID, value)
      }
    },
    spacebarHints: {
      get() {
        return !this.user?.spacebarHintsDisabled
      },
      set(value) {
        this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: { spacebarHintsDisabled: !value }
        })
      }
    },
    presentersSidebar: {
      get() {
        return !this.game.sidebarHidden
      },
      set(value) {
        db.auxiliary()
          .ref(`org/${this.orgID}/games/${this.gameID}/sidebarHidden`)
          .set(!value)
      }
    },
    auditorsQty() {
      return this.onlineUsers.filter(user => user.role === Role.Audit).length
    },
    tipJar: {
      get() {
        return !!this.game?.showTipJar
      },
      set(value) {
        db.auxiliary()
          .ref(`org/${this.orgID}/games/${this.gameID}/showTipJar`)
          .set(!!value)
      }
    },
    isVisible: {
      get() {
        return this.settingsBar
      },
      set(value) {
        this.$store.dispatch("setSettingsBar", value)
        this.toggleSubSettingsBar()
      }
    },
    currentMission() {
      return this.gameStatus?.current_mission
    },
    originalGameID() {
      return this.game.originalGameID || this.gameID
    },
    mission() {
      return this.gameStatus?.current_mission
    }
  },
  created() {
    this.raffleService = new RaffleService()
  },
  beforeDestroy() {
    this.isVisible = false
  },
  watch: {
    subSettings(val) {
      this.subMenuWidth = 270
      this.subMenuBackground = "#292932"

      if (val === SUB_SETTINGS_BARS.PersonalSettings) {
        this.subMenuWidth = 358
      }

      if (val === SUB_SETTINGS_BARS.TextSettings) {
        this.subMenuWidth = 200
      }

      if (val === SUB_SETTINGS_BARS.Hotkeys) {
        this.subMenuBackground = "#1e2025"
      }
    }
  },
  methods: {
    deletePlayData() {
      this.$confirm({
        message:
          "Are you sure you want to DELETE ALL PLAY DATA? This is irreversible",
        buttonColor: "danger",
        yesText: "Yes, Delete Play Data"
      }).then(async () => {
        this.working = true
        await NavigationService.deletePlays(this.orgID, this.gameID)
        this.working = false
        this.hideSettingsBar()
      })
    },
    refresh() {
      this.refreshKey += 1
    },
    ...mapActions("TwilioStream", {
      toggleTwilioStream: TwilioStreamActionTypes.TOGGLE_STREAM
    }),
    ...mapActions(["updateShowScreenCaptureDialog", "toggleGameModeratorID"]),
    ...mapActions("Games", ["updateGameAny"]),
    updateShowSubMenu(val) {
      this.showSubMenu = val
    },
    hideSettingsBar() {
      this.toggleSubSettingsBar()
      this.state.value = false
    },
    showHotkeysSidebar() {
      this.$store.commit("SET_HOTKEYS_BAR", true)
      this.$store.dispatch("setSettingsBar", false)
    },
    toggleSubSettingsBar(name = false) {
      this.showSubMenu = !!name
      this.subSettings = name
    },
    async flagContent(message) {
      this.$store.dispatch("updateMission", {
        flagged: {
          flaggedFor: message,
          flaggedBy: `${this.user.firstname} ${this.user.lastname}`,
          timestamp: Date.now()
        },
        theKey: this.mission.id
      })

      try {
        await sendEmergencyTicket({
          userID: this.user.id,
          name: `${this.user.firstname} ${this.user.lastname}`,
          title: this.game.name,
          gameID: this.gameID,
          clientID: this.clientID,
          orgID: this.orgID,
          url: window.location.href,
          message: `${this.mission.name}: ${message}`
        })
        this.$info("Sent! We greatly appreciate your feedback.")
      } catch (e) {
        console.error(e)
        this.$info(e.message)
      }
    },
    async submitFlag() {
      this.flagDialogVisibility = false
      this.suggestDialogVisibility = false
      await this.flagContent(this.flagMessage)
      this.flagMessage = ""
    },
    closeFlagDialog() {
      this.flagDialogVisibility = false
      this.suggestDialogVisibility = false
    },
    callAction(actionName, message) {
      if (this[actionName]) {
        this[actionName](message)
      }
    },
    showFlagDialog() {
      this.flagDialogVisibility = true
      this.$nextTick(() => {
        this.$refs.flagArea.$el.childNodes[0].focus()
      })
    },
    showSuggestDialog() {
      this.suggestDialogVisibility = true
      this.$nextTick(() => {
        this.$refs.suggestionArea.$el.childNodes[0].focus()
      })
    },
    copyLink() {
      this.$clipboard(window.location.host + `/gamelogin/${this.urlID}?auth=0`)
      setTimeout(() => {
        this.isCopyLinkTTVisible = true
      }, 500)
    },
    onLiveStream() {
      this.updateShowScreenCaptureDialog(true)
      this.hideSettingsBar()
    },
    async resetGame() {
      try {
        await this.$confirm({
          message:
            "Are you sure you want to RESET THE WHOLE GAME? This is irreversible",
          buttonColor: "danger",
          yesText: "Yes, Reset Game"
        })
        // TODO replace to global working to show/hide mission snack loader
        this.working = true
        await NavigationService.reset(this.orgID, this.gameID)
      } catch (e) {
        console.error(e)
      }
      // TODO replace to global working to show/hide mission snack loader
      this.working = false
      this.hideSettingsBar()
    }
  }
})
