var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personal-settings-panel" },
    [
      _c(
        "v-tabs",
        {
          attrs: { dark: "", color: "#292932" },
          model: {
            value: _vm.personalSettingsTab,
            callback: function ($$v) {
              _vm.personalSettingsTab = $$v
            },
            expression: "personalSettingsTab",
          },
        },
        [
          _c("v-tab", { key: "tips", staticClass: "tab" }, [
            _vm._v("Tips & Referral"),
          ]),
          _c("v-tab", { key: "twitch", staticClass: "tab" }, [
            _vm._v("Twitch"),
          ]),
          _c("v-tab", { key: "audio", staticClass: "tab" }, [_vm._v("Audio")]),
          _c("v-tab-item", { key: "tips" }, [
            _c(
              "div",
              { staticClass: "tab-item-container px-3" },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "mb-2 mt-1",
                    attrs: { "d-flex": "", column: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass:
                          "tab-item-container__info-text text-center mt-3 mb-3",
                      },
                      [
                        _vm._v(
                          " The Tip Jar and Referral Link can be toggled on in the ‘Show’ menu of the host toolbar in meeting mode "
                        ),
                      ]
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "tab-item-container__list",
                        attrs: { "d-flex": "", column: "" },
                      },
                      [
                        _c("h3", { staticClass: "mb-1" }, [_vm._v("Tip Jar")]),
                        _c("WeveTextField", {
                          staticClass: "mt-2",
                          attrs: {
                            label: "Venmo QR code URL",
                            placeholder: "www.venmo.com/johndoe",
                          },
                          model: {
                            value: _vm.venmo,
                            callback: function ($$v) {
                              _vm.venmo = $$v
                            },
                            expression: "venmo",
                          },
                        }),
                        _c("WeveTextField", {
                          staticClass: "mt-4",
                          attrs: {
                            label: "Paypal username",
                            placeholder: "johndoe",
                          },
                          model: {
                            value: _vm.paypal,
                            callback: function ($$v) {
                              _vm.paypal = $$v
                            },
                            expression: "paypal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("hr", { staticClass: "hr-divider mt-4" }),
                    _c(
                      "v-layout",
                      {
                        staticClass: "tab-item-container__list mt-3",
                        attrs: { "d-flex": "", column: "" },
                      },
                      [
                        _c("h3", [_vm._v("Referral Link")]),
                        _c("WeveTextField", {
                          staticClass: "mt-3",
                          attrs: {
                            label: "Referral link",
                            placeholder: "e.g. http://landingpage.com/johndoe",
                          },
                          model: {
                            value: _vm.referralLink,
                            callback: function ($$v) {
                              _vm.referralLink = $$v
                            },
                            expression: "referralLink",
                          },
                        }),
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "d-flex": "",
                              "align-center": "",
                              "justify-space-between": "",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(" Show end game referral link iframe "),
                            ]),
                            _c("v-switch", {
                              staticStyle: { flex: "none !important" },
                              attrs: { color: "#8680FF" },
                              model: {
                                value: _vm.showReferralLinkIframe,
                                callback: function ($$v) {
                                  _vm.showReferralLinkIframe = $$v
                                },
                                expression: "showReferralLinkIframe",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "text-xs-center mt-5 tab-item-container__action-btn",
                        attrs: {
                          round: "",
                          block: "",
                          loading: _vm.processingSaveReferral,
                        },
                        on: { click: _vm.saveTipsAndReferral },
                      },
                      [_vm._v(" SAVE ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-tab-item", { key: "twitch" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 13px" } },
              [
                _c("WeveTextField", {
                  staticClass: "mt-3",
                  attrs: { label: "Twitch Name", placeholder: "Weve2" },
                  model: {
                    value: _vm.twitchAccountName,
                    callback: function ($$v) {
                      _vm.twitchAccountName = $$v
                    },
                    expression: "twitchAccountName",
                  },
                }),
                _c("WeveTextField", {
                  staticClass: "mt-3",
                  attrs: { label: "Twitch URL", placeholder: "johndoe" },
                  model: {
                    value: _vm.twitchAccountUrl,
                    callback: function ($$v) {
                      _vm.twitchAccountUrl = $$v
                    },
                    expression: "twitchAccountUrl",
                  },
                }),
                _c("WeveTextField", {
                  staticClass: "mt-3",
                  attrs: {
                    label: "Twitch API Key",
                    placeholder: "live_78493h48h8wyerr",
                  },
                  model: {
                    value: _vm.twitchAccountApiKey,
                    callback: function ($$v) {
                      _vm.twitchAccountApiKey = $$v
                    },
                    expression: "twitchAccountApiKey",
                  },
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-xs-center mt-5",
                    staticStyle: {
                      "font-weight": "bold",
                      "text-align": "center",
                      color: "black !important",
                    },
                    attrs: {
                      round: "",
                      block: "",
                      color: "rgb(255, 212, 76)",
                      loading: _vm.processingTwitchSetting,
                    },
                    on: { click: _vm.updateTwitchAccount },
                  },
                  [_vm._v("SAVE")]
                ),
              ],
              1
            ),
          ]),
          _c("v-tab-item", { key: "audio" }, [
            _c(
              "div",
              { staticClass: "tab-item-container" },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "mb-2 mt-1",
                    attrs: { "d-flex": "", column: "" },
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "tab-item-container__list",
                        attrs: {
                          "d-flex": "",
                          "align-center": "",
                          "justify-space-between": "",
                        },
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "tab-item-container__list-title" },
                          [_vm._v(" Turn off auto music triggers ")]
                        ),
                        _c("v-switch", {
                          staticStyle: { flex: "none !important" },
                          attrs: { color: "#8680FF" },
                          model: {
                            value: _vm.disableAutoMusicModel,
                            callback: function ($$v) {
                              _vm.disableAutoMusicModel = $$v
                            },
                            expression: "disableAutoMusicModel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tab-item-container__info-text" },
                      [
                        _vm._v(
                          " When toggled on, music attached to activities will not automatiaclly play "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "text-xs-center mt-5 tab-item-container__action-btn",
                    attrs: {
                      round: "",
                      block: "",
                      loading: _vm.processingAudioSetting,
                    },
                    on: { click: _vm.updateAutoMusicSetting },
                  },
                  [_vm._v("SAVE")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }