<template>
  <div>
    <div
      v-for="user in nonHostOnlineUsers"
      class="sub-settings-bar__list-item"
      :key="user.id"
    >
      <div
        class="sub-settings-user"
        @click="toggleGameModeratorID(user && user.id)"
      >
        <div class="sub-settings-user__img">
          <UserImage :imageUrl="user.image || undefined" />
        </div>
        <div class="sub-settings-user__name">
          {{ user.firstname }}
          {{ user.lastname.charAt(0) }}
        </div>
        <div class="sub-settings-user__icon">
          <v-icon v-if="user.id === moderatorID"> check </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { Role } from "@/helpers"

import UserImage from "@/components/GroupTeams/Common/User/UserImage.vue"

export default {
  name: "SelectModeratorPanel",
  data() {
    return {
      Role
    }
  },
  components: {
    UserImage
  },
  computed: {
    ...mapGetters({
      onlineUsers: "onlineUsersArray"
    }),
    nonHostOnlineUsers() {
      return this.onlineUsers.filter(user => user.role !== Role.Host)
    }
  },
  methods: {
    ...mapActions(["toggleGameModeratorID"])
  }
}
</script>
