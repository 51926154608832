<template>
  <v-layout column class="sub-settings-bar__teams">
    <div class="sub-settings-bar__teams--container">
      <v-layout
        column
        no-wrap
        class="sub-settings-bar__teams--team"
        v-for="team in teams"
        :key="team.id"
      >
        <v-layout row class="sub-settings-bar__teams--team__name_delete">
          <v-flex
            class="d-flex sub-settings-bar__teams--team__name align-self-start"
          >
            {{ team.name }}
          </v-flex>
          <v-flex
            class="d-flex sub-settings-bar__teams--team__delete align-self-end shrink"
          >
            <v-btn flat @click="dismissTeam(team)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row class="sub-settings-bar__teams--team__controls">
          <v-flex shrink>
            <button
              class="circle minus"
              @click="removeFirstTestUserFromTeam(team)"
            />
          </v-flex>
          <v-flex grow class="team-value">
            <span>{{ team.count }}</span>
          </v-flex>
          <v-flex shrink>
            <button class="circle plus" @click="addTestUser(team)" />
          </v-flex>
        </v-layout>
      </v-layout>
    </div>

    <v-flex class="sub-settings-bar__teams--team--add-team mt-3">
      <button d-flex @click="addTeam">
        <div class="circle plus"></div>
        <div
          style="
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
            margin-left: -22px;
          "
        >
          Add Team
        </div>
      </button>
    </v-flex>
    <v-btn
      flat
      small
      class="mt-4 danger"
      v-if="hasHeadlessUsers"
      @click="deleteAll()"
    >
      DELETE TEST PLAYERS
    </v-btn>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import getRandomInt from "@shared/helpers/getRandomInt"
import TEAM_ICONS from "@shared/team-icons"
import Team from "@shared/Team"
import User from "@shared/User"

export default {
  name: "TestAttendees",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["chats", "onlineUsersGroupedByTeam"]),
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters("auth", ["clientID", "user", "token"]),
    ...mapGetters(["orgID", "gameID"]),
    teams() {
      const onlineUsersGroupedByTeam = this.onlineUsersGroupedByTeam ?? {}
      return Team.normalize(this.chats).map(team => ({
        ...team,
        count: onlineUsersGroupedByTeam[team.id]?.length ?? 0
      }))
    },
    headlessUsers() {
      return User.normalize(this.users ?? {}).filter(User.isHeadless)
    },
    hasHeadlessUsers() {
      return this.headlessUsers.length > 0
    }
  },
  methods: {
    ...mapActions(["createTeam", "deleteTeam"]),
    ...mapActions("GameUsers", [
      "addTestUser",
      "removeFirstTestUserFromTeam",
      "removeTestUsers"
    ]),
    async addTeam() {
      const teams = this.teams
      const pool = TEAM_ICONS.filter(string =>
        teams.every(({ icon }) => icon !== string)
      )

      const icon = pool[getRandomInt(0, pool.length - 1)] ?? TEAM_ICONS[0]

      const obj = {
        name: `Team ${teams.length + 1}`,
        icon,
        show: true,
        muted: false,
        totalScore: 0
      }

      await this.createTeam(obj)
    },
    dismissTeam(team) {
      this.deleteTeam(team)
    },
    deleteAll() {
      this.removeTestUsers({ users: this.headlessUsers })
    }
  }
}
</script>

<style lang="scss">
.sub-settings-bar {
  &__teams {
    padding: 15px;
    &--container {
      max-height: 60vh;
      overflow-y: auto;
    }
    &--team {
      margin: 10px auto;
      &__name {
        overflow: hidden;
      }
      &__name_delete {
        height: 19px;
        margin: 2px;
      }
      button {
        padding: 0px !important;
        &.v-btn {
          min-width: 18px;
          height: 18px;
        }
        .v-icon {
          line-height: 1;
          font-size: 14px;
          padding: 2px;
        }
        .v-btn__content {
          text-align: center !important;
          justify-content: center !important;
        }
        &:focus {
          outline: none;
        }
      }
      &__controls {
        height: 40px;
        border: 1px solid #393b42;
        border-radius: 3px;
        background: #393b42;
        align-items: center;
        margin-top: 10px;
        padding: 10px 15px;
        button {
          position: relative;
          width: 100%;
          text-transform: uppercase;
          border-radius: 50% !important;
          font-weight: bolder;
          display: grid;
          place-content: center;
          width: 20px !important;
          height: 20px !important;
          display: flex;
          align-items: center;
          font-size: 10px !important;
          color: rgba(57, 59, 66, 1) !important;
          font-weight: bolder !important;
          font-size: 22px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          font-size: 22px !important;
        }
        .team-value {
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      &--add-team {
        display: flex;
        justify-content: flex-end;
        display: flex;
        button {
          height: 30px;
          // width: 80% !important;
          border-radius: 20px !important;
          padding: 12px 7.5px !important;
          background: #37393d !important;
          border-radius: 20px !important;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          display: flex !important;
          flex-direction: row;
          align-items: center;
          text-transform: uppercase;
          &:hover {
            background: rgb(85, 85, 85) !important;
          }
          &:focus {
            outline: none;
          }
        }
        .circle {
          background: #dadada;
          width: 15px !important;
          height: 15px !important;
          margin-right: 12px !important;
          &:hover {
            background: #dadada !important;
          }
          &.plus:before {
            margin: 2px auto;
          }
          &.plus:after {
            margin: auto 2px;
          }
        }
      }
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      position: relative;
      margin: 5px;
      display: inline-block;
      vertical-align: middle;
      background: #dadada !important;
      &:hover {
        background: #bebdbd !important;
      }
      &:active {
        background: radial-gradient(#dadada, #fff);
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &.minus:before,
      &.plus:before,
      &.plus:after {
        background: #1d2125;
      }
      &.plus:before {
        width: 2px;
        margin: 3px auto;
      }
      &.plus:after {
        margin: auto 3px;
        height: 2px;
      }
      &.minus:before {
        margin: auto 3px;
        height: 2px;
      }
    }
  }
}
</style>
