<template>
  <div class="hotkeys-sidebar__content">
    <RtbToggleButton
      class="hotkeys-sidebar__shortcuts-switch"
      v-model="toggledMode"
      :items="toggleModes"
    />
    <div class="hotkeys-sidebar__container">
      <div
        class="hotkeys-sidebar__item"
        v-for="(hotkey, i) in keyList"
        :key="hotkey.name + i"
      >
        <div class="hotkeys-sidebar__icon-container">
          <HotKeyIcon :hotkey="hotkey" />
        </div>
        <div class="hotkeys-sidebar__name-container">
          <div class="hotkeys-sidebar__name">{{ hotkey.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { RtbToggleButton } from "@/components/ui"

import hotkeys from "./hotkeys"
import HotKeyIcon from "./HotKeyIcon.vue"

export default {
  name: "HotkeysSidebar",
  model: {
    prop: "visible",
    event: "input"
  },
  components: {
    RtbToggleButton,
    HotKeyIcon
  },
  props: ["visible"],
  data() {
    return {
      toggleModes: [
        {
          name: "ESSENTIAL",
          value: "essentialHotkeys"
        },
        {
          name: "GIFS",
          value: "gifHotkeys"
        }
      ],
      toggledMode: "essentialHotkeys",
      ...hotkeys
    }
  },
  computed: {
    ...mapState({
      hotkeysBar: state => state.hotkeysBar
    }),
    isVisible: {
      get() {
        return this.hotkeysBar
      },
      set(value) {
        this.$store.commit("SET_HOTKEYS_BAR", value)
      }
    },
    keyList() {
      return this[this.toggledMode]
    }
  },
  methods: {
    back() {
      this.$store.commit("SET_HOTKEYS_BAR", false)
      this.$store.dispatch("setSettingsBar", true)
    }
  }
}
</script>
<style lang="scss">
.hotkeys-sidebar {
  z-index: 100; // above iframe
  margin-right: 60px;
  color: #d2d6db;
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  width: 200px;

  &__shortcuts-switch {
    margin: 15px 10px;
    align-self: center;
    width: 70%;
    height: 35px;
  }

  &__title {
    margin-left: 5px;
    padding: 10px 0;
  }

  &__container {
    padding: 0 10px;
  }

  &__content {
    min-height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;

    margin: 6.5px 0;
  }

  &__name-container {
    width: 75%;
  }

  &__name {
    margin-left: 16px;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }

  &__icon-container {
    width: 20%;
  }
}
</style>
