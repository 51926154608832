






























import Vue from "vue"
export default Vue.extend({
  name: "MainDrawerBtn",
  props: {
    title: {
      type: String,
      default: undefined
    },
    chevron: {
      type: Boolean,
      default: undefined
    },
    svgPrepend: {
      type: String,
      default: undefined
    },
    iconPrepend: {
      type: String,
      default: undefined
    },
    svgApppend: {
      type: String,
      default: undefined
    },
    svgPrependSize: {
      type: Number,
      default: 13
    },
    iconPrependSize: {
      type: Number,
      default: 13
    }
  }
})
