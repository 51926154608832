export const SUB_SETTINGS_BARS = {
  SelectModerator: "Select Moderator",
  TestAttendees: "Test Attendees",
  TextSettings: "Text Settings",
  PersonalSettings: "Personal Settings",
  Hotkeys: "Hotkeys"
}

export const flagActions = {
  flagContent: "flagContent",
  showFlagDialog: "showFlagDialog",
  showSuggestDialog: "showSuggestDialog"
}

export const flagOptions = [
  {
    title: "Content Flagging",
    action: flagActions.flagContent
  },
  {
    title: "Amazing Activity!",
    action: flagActions.flagContent
  },
  { type: "divider" },
  {
    title: "Nobody Knew This",
    action: flagActions.flagContent
  },
  {
    title: "Spelling/Grammar",
    action: flagActions.flagContent
  },
  {
    title: "Media Error",
    action: flagActions.flagContent
  },
  {
    title: "Offensive",
    action: flagActions.flagContent
  },
  { type: "divider" },
  {
    title: "Describe",
    action: flagActions.showFlagDialog
  },
  {
    title: "Suggestion",
    action: flagActions.showSuggestDialog
  }
]
