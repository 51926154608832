export default {
  essentialHotkeys: [
    {
      name: "Next mission",
      text: `Space`,
      sm: true
    },
    {
      name: "Back a mode on same mission",
      text: `Delete`,
      sm: true
    },
    {
      name: "Bring in new LipDub players",
      iconName: "shift-key",
      text: `+ Space`,
      sm: true
    },
    {
      name: "Scroll missions list",
      iconName: "arrows",
      iconWidth: 28,
      iconHeight: 28
    },
    {
      name: "Select mission scrolled to with",
      iconName: "enter",
      iconWidth: 18,
      iconHeight: 18
    },
    {
      name: "View different team",
      text: `[ &nbsp; ]`
    },
    {
      name: "Enable All Talk",
      text: `N`
    },
    {
      name: "Enable Host Only",
      text: `M`
    },
    {
      name: "Select next Scribes",
      text: `Q`
    },
    {
      name: "Game over",
      iconName: "shift-key",
      text: `G`
    },
    {
      name: "Toggle Music Drawer",
      iconName: "shift-key",
      text: `J`
    },
    {
      name: "Advance to award show",
      iconName: "shift-key",
      text: `W`
    }
  ],

  gifHotkeys: [
    {
      name: "Fun background GIF",
      iconName: "shift-key",
      text: "F"
    },
    {
      name: "Fun background GIF",
      iconName: "shift-key",
      text: "D"
    },
    {
      name: "Fun background GIF",
      iconName: "shift-key",
      text: "S"
    },
    {
      name: "Fun background GIF",
      iconName: "shift-key",
      text: "A"
    },
    {
      name: "Fun background GIF",
      iconName: "shift-key",
      text: "T"
    }
  ]
}
