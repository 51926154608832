


















import Vue from "vue"
export default Vue.extend({
  name: "HotKeyIcon",
  props: {
    hotkey: {
      type: Object,
      default: undefined
    },
    iconName: {
      type: String,
      default: undefined
    },
    iconWidth: {
      type: [Number, String],
      default: 14
    },
    iconHeight: {
      type: [Number, String],
      default: 14
    },
    text: {
      type: String,
      default: undefined
    },
    sm: {
      type: Boolean,
      default: undefined
    }
  }
})
