var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-settings-panel px-2" },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            _vm._l(_vm.textChatOptionsComputed, function (chatOption, index) {
              return _c(
                "v-flex",
                {
                  key: index,
                  staticClass: "mr-1",
                  attrs: {
                    "d-flex": "",
                    "justify-space-between": "",
                    "align-center": "",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v(" chat ")]
                      ),
                      _c("span", [_vm._v(_vm._s(chatOption.label))]),
                    ],
                    1
                  ),
                  _c("v-switch", {
                    staticStyle: { flex: "none !important" },
                    attrs: { color: "#8680FF" },
                    model: {
                      value: _vm.textChatComputed[chatOption.value],
                      callback: function ($$v) {
                        _vm.$set(_vm.textChatComputed, chatOption.value, $$v)
                      },
                      expression: "textChatComputed[chatOption.value]",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-btn",
            {
              staticStyle: { "font-weight": "bold" },
              attrs: { color: "#FF4060", round: "" },
              on: { click: _vm.clearTeamChat },
            },
            [_vm._v("CLEAR CHAT")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-layout",
        {
          attrs: {
            "d-flex": "",
            "align-center": "",
            "justify-space-between": "",
          },
        },
        [
          _c(
            "div",
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v(" closed_caption "),
              ]),
              _c("span", [_vm._v("Closed captions")]),
            ],
            1
          ),
          _c("v-switch", {
            staticStyle: { flex: "none !important" },
            attrs: { color: "#8680FF" },
            model: {
              value: _vm.speechToText,
              callback: function ($$v) {
                _vm.speechToText = $$v
              },
              expression: "speechToText",
            },
          }),
        ],
        1
      ),
      _vm.speechToText
        ? _c("weve-select-field", {
            staticClass: "text-settings-panel__languages_select",
            attrs: {
              disabled: !_vm.speechToText,
              options: _vm.speechLanguages,
              identity: "languageCode",
              "option-label": "language",
              "option-value": "languageCode",
              label: "Language",
            },
            on: { input: _vm.onLanguageChange },
            model: {
              value: _vm.game.speechLanguage,
              callback: function ($$v) {
                _vm.$set(_vm.game, "speechLanguage", $$v)
              },
              expression: "game.speechLanguage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }