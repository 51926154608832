var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.nonHostOnlineUsers, function (user) {
      return _c(
        "div",
        { key: user.id, staticClass: "sub-settings-bar__list-item" },
        [
          _c(
            "div",
            {
              staticClass: "sub-settings-user",
              on: {
                click: function ($event) {
                  return _vm.toggleGameModeratorID(user && user.id)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "sub-settings-user__img" },
                [
                  _c("UserImage", {
                    attrs: { imageUrl: user.image || undefined },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "sub-settings-user__name" }, [
                _vm._v(
                  " " +
                    _vm._s(user.firstname) +
                    " " +
                    _vm._s(user.lastname.charAt(0)) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "sub-settings-user__icon" },
                [
                  user.id === _vm.moderatorID
                    ? _c("v-icon", [_vm._v(" check ")])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }