var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        { staticClass: "host-sidebar__btn-item", attrs: { flat: "" } },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.svgPrepend
        ? _c("SvgIcon", {
            staticClass: "mr-2",
            attrs: {
              name: _vm.svgPrepend,
              width: _vm.svgPrependSize,
              height: _vm.svgPrependSize,
            },
          })
        : _vm._e(),
      _vm.iconPrepend
        ? _c(
            "v-icon",
            {
              staticClass: "mr-2",
              style: { fontSize: _vm.iconPrependSize + "px" },
            },
            [_vm._v(" " + _vm._s(_vm.iconPrepend) + " ")]
          )
        : _vm._e(),
      _vm.title ? _c("span", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _vm._t("default"),
      _vm.chevron
        ? _c("SvgIcon", {
            staticClass: "ml-auto",
            attrs: {
              name: "chevron-right-regular",
              width: "4",
              height: "10",
              color: "#798192",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }